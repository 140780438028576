
import { Component } from 'vue-property-decorator'
import Emitter from '@/utils/emitter'
import { ElForm } from 'element-ui/types/form'
import ZButton from '@/components/base/z-button.vue'
import { isWechat } from '@/utils/utils'
import { formatDate } from '@/utils/date'

@Component({
	components: { ZButton },
	filters: {
		formatDate(value: number) {
			return value ? formatDate('yyyy-MM-dd h:i:s', value) : '-'
		}
	}
})
export default class extends Emitter {
	public $refs!: {
		form: ElForm
		audio: HTMLAudioElement
		audioScaned: HTMLAudioElement
	}
	private isWechat = false
	private inputCodeStr = ''
	private isLoading = false
	private packageAttrList: any = []
	private total = 0
	private searchForm: any = {
		package_attr_num: []
	}
	// private validateForm = {
	// 	package_attr_num: [{ required: true, message: '请输入包裹号', trigger: 'blur' }]
	// }
	async created() {
		this.isWechat = isWechat()
	}
	async initJSSdk() {
		if (!this.isWechat) return false
		const url = location.href.split('#')[0]
		const { data } = await this.$axios.post('v1/jobline/js-sdk-config', {
			url
		})
		this.$wx.config({
			debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
			...data,
			jsApiList: ['scanQRCode'] // 必填，需要使用的JS接口列表
		})
	}
	async parseCode() {
		const packageAttrNums: string[] = []
		new Set(
			this.inputCodeStr
				.trim()
				.split(/[;,，\n ]/)
				.map(item => {
					packageAttrNums.push(item)
				})
		)
		const { data } = await this.$axios
			.post('/v1/jobline/package_attr_shelf/find', {
				package_attr_nums: packageAttrNums
			})
			.catch(e => {
				console.log(e)
				return {
					data: null
				}
			})
			.finally(() => (this.isLoading = false))
		if (!data) return
		console.log(data)
		data.map((item: any) => {
			item.size = `${item.length}*${item.height}*${item.width}`
			item.weight = `${(item.weight / 1000).toFixed(3)}kg`
			item.rate_weight = `${(item.rate_weight / 1000).toFixed(3)}kg`
		})
		this.packageAttrList = data
		this.total = data.length || 0
	}
}
